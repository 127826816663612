import sizeMaker from "../sizeMaker";

export default [
  {
    title: "21.010.03-АС-Ч-20-ОР5471235475123-14-001-0.psd",
    status: "pen",
    author: "Новиков Николай",
    updatedAt: "30.10.2020",
    type: "acrobat",
    size: sizeMaker(5000),
    project: "21.010.03 Газго",
    updatedBy: "Новиков Николай",
    takenBy: "Новиков Николай",
  },
  {
    title: "21.010.03-АС-Ч-20-ОР5471235475123-14-001-0.psd",
    status: "lock",
    author: "Жургенов Абас",
    updatedAt: "30.10.2020",
    type: "autocad",
    size: sizeMaker(5000),
    project: "21.010.03 Газго",
    updatedBy: "Жургенов Абас",
    takenBy: "Жургенов Абас",
  },
  {
    title: "21.010.03-АС-Ч-20-ОР5471235475123-14-002-0.doc",
    status: "read",
    author: "Ли Сергей",
    updatedAt: "30.10.2020",
    type: "word",
    size: sizeMaker(2000),
    project: "21.010.03 Газго",
    updatedBy: "Ли Сергей",
    takenBy: "Жургенов Абас",
  },
  {
    title: "21.010.03-АС-Ч-20-ОР5471235475123-14-003-0.png",
    status: "pen",
    author: "Новиков Николай",
    updatedAt: "30.10.2020",
    type: "image",
    size: sizeMaker(5000),
    project: "21.010.03 Газго",
    updatedBy: "Ли Сергей",
    takenBy: "Жургенов Абас",
  },
];
